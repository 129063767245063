import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import { ResponsiveMaxWidthContent } from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import FAQ from "../../../../components/FAQ/FAQ"
import { StyledList } from "../../../CoachScreen/components/CoachFAQS/CoachFAQS"
import MediumHeading from "../../../../ui/typography/MediumHeading/MediumHeading"
import { DESKTOP_BREAKPOINT } from "../../../../ui/responsive"
import { desktopGridCss } from "../../../../ui/shared"
import { IFAQ } from "../../../../data/cms/home"
import { ROUTES } from "../../../../navigation/routes"
import { useHelpUrl } from "../../../../components/ForgotDetailsModal/ForgotDetailsModal"

const StyledContainer = styled.section`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
`

const StyledHeading = styled(MediumHeading)`
  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: 30px;
  }
`

const StyledLinkWrapper = styled.div`
  text-align: center;
  margin-top: 24px;

  a {
    text-decoration: underline;
  }
`

const StyledContentWrapper = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
  }
`

const StyledContent = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: 2 / span 4;
  }
`

const FAQSection: React.FC<{
  heading: string
  faqs: IFAQ[]
}> = ({ heading, faqs }) => {
  const helpUrl = useHelpUrl()
  return (
    <StyledContainer>
      <StyledContentWrapper>
        <StyledContent>
          <StyledHeading>{heading}</StyledHeading>
          <StyledList>
            {faqs.map((faq, index) => {
              return (
                <li key={index.toString()}>
                  <FAQ redIcon question={faq.question} answer={faq.answer} />
                </li>
              )
            })}
          </StyledList>
          <StyledLinkWrapper>
            <a href={helpUrl} target="_blank" rel="noopener noreferrer">
              Get more Help
            </a>
          </StyledLinkWrapper>
        </StyledContent>
      </StyledContentWrapper>
    </StyledContainer>
  )
}

export default FAQSection
