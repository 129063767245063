import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import theme from "../../../../ui/theme"
import {
  responsiveScrollableContainerCss,
  responsiveScrollableContainerDesktopLeftPaddingCss,
  responsiveScrollableContainerDesktopRightPaddingCss,
} from "../../../../ui/layout"
import CoachCard from "../../../../components/CoachCard/CoachCard"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../../../ui/responsive"
import ActionButton from "../../../../ui/buttons/ActionButton/ActionButton"
import {
  getCoachId,
  getCoachImage,
  getCoachName, getCoachPreviewPrice,
  getCoachPreviewSlug,
  isPlaceholder,
  ICoachPreviewGraph,
} from "../../../../data/cms/coachPreview"
import { ROUTES } from "../../../../navigation/routes"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../../../ui/typography/fonts"

export const StyledContainer = styled.section`
  ${DESKTOP_BREAKPOINT} {
    ${responsiveScrollableContainerDesktopLeftPaddingCss};
    display: flex;
  }
`

export const StyledTitle = styled.h3`
  margin-bottom: 10px;
  font-size: ${theme.fontSizes.mediumPlus}px;
  line-height: 30px;
  font-weight: ${theme.fontWeights.bold};

  ${DESKTOP_BREAKPOINT} {
    ${fancyCondensedFontCss};
    font-size: 38px;
    line-height: 36px;
    margin-bottom: 12px;
    text-transform: uppercase;

    span:first-child {
      ${fancyWideFontCss};
      color: ${theme.colors.secondary};
      font-size: 64px;
      line-height: 50px;
    }

    span:nth-child(2),
    span:last-child {
      ${fancyWideFontCss};
      font-size: 38px;
      line-height: 36px;
    }

    span:last-child {
      color: ${theme.colors.primary};
    }
  }
`

export const StyledHeader = styled(MaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    margin-right: 23px;
    width: 100%;
    max-width: 271px;
    padding-top: 114px;

    p {
      font-size: ${theme.fontSizes.medium}px;
      line-height: ${theme.lineHeights.medium}px;
      max-width: 224px;
      margin-bottom: 34px;
    }

    a,
    button {
      max-width: 253px;
      margin-left: -5px;
    }
  }
`

export const StyledListContainer = styled.ul`
  > li:not(:last-child) {
    margin-right: 24px;
  }

  ${MOBILE_BREAKPOINT} {
    ${responsiveScrollableContainerCss};
  }
  ${DESKTOP_BREAKPOINT} {
    display: flex;
    flex: 1;
    overflow-x: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: -15px;
    margin-bottom: -15px;

    > li:first-child {
      padding-left: 23px;
    }

    > li:last-child {
      ${responsiveScrollableContainerDesktopRightPaddingCss};
    }
  }
`

const TrendingCoaches: React.FC<{
  coaches: ICoachPreviewGraph[]
  subheading: string
}> = ({ coaches, subheading }) => (
  <StyledContainer>
    <StyledHeader>
      <StyledMobileOnly>
        <StyledTitle>Trending Coaches</StyledTitle>
      </StyledMobileOnly>
      <StyledDesktopOnly>
        <StyledTitle>
          <span>
            Trend
            <br />
            ing
          </span>{" "}
          <br />
          <span>Coaches</span>
          <span>.</span>
        </StyledTitle>
        <p>{subheading}</p>
        <div>
          <ActionButton as={Link} to={ROUTES.discover.path}>
            Discover more
          </ActionButton>
        </div>
      </StyledDesktopOnly>
    </StyledHeader>
    <StyledListContainer>
      {coaches.map(coach => (
        <li key={coach.coachId}>
          <CoachCard
            subscriptionPrice={getCoachPreviewPrice(coach)}
            coachId={getCoachId(coach)}
            slug={getCoachPreviewSlug(coach)}
            name={getCoachName(coach)}
            image={getCoachImage(coach)}
            isPlaceholder={isPlaceholder(coach)}
          />
        </li>
      ))}
    </StyledListContainer>
  </StyledContainer>
)

export default TrendingCoaches
