import { useCoachEntryLocation } from "../analytics/components/PageLocation/PageLocation"
import { setCoachEntryPoint } from "../screens/CoachScreen/CoachScreen"

export const useOnCoachClick = (): ((coachId: string) => void) => {
  const pageLocation = useCoachEntryLocation()
  const onClick = (coachId: string) => {
    setCoachEntryPoint(coachId, pageLocation)
  }
  return onClick
}
