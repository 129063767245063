import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import HomeScreen from "../screens/HomeScreen/HomeScreen"
import { IHomeScreenGraph } from "../data/cms/home"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { COACH_ENTRY_POINTS } from "../screens/CoachScreen/CoachScreen"
import { ROUTES } from "../navigation/routes"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebHomeScreenDev?: IHomeScreenGraph
  datoCmsWebHomeScreenStg?: IHomeScreenGraph
  datoCmsWebHomeScreen?: IHomeScreenGraph
  datoCmsWebHomeScreenPrd?: IHomeScreenGraph
}

interface Props {
  data: Data
}

const IndexPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebHomeScreenDev",
    stgKey: "datoCmsWebHomeScreenStg",
    uatKey: "datoCmsWebHomeScreen",
    prdKey: "datoCmsWebHomeScreenPrd",
    defaultKey: "datoCmsWebHomeScreen",
  })
  const cmsData: IHomeScreenGraph = get(props, `data.${contentKey}`)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation
          coachEntryLocation={COACH_ENTRY_POINTS.Home}
          pageName={ROUTES.home.name}
        >
          <PageMetaTags data={props} />
          <HomeScreen cmsData={cmsData} />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export const query = graphql`
  query HomeScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebHomeScreenDev @include(if: $devEnv) {
      ...HomeScreenDev
      developmentTrendingCoaches {
        ...DevCoachPreview
      }
      developmentLatestLives {
        ...DevLatestLive
      }
    }
    datoCmsWebHomeScreenStg @include(if: $stgEnv) {
      ...HomeScreenStg
      stagingTrendingCoaches {
        ...CoachPreview
      }
      stagingLatestLives {
        ...LatestLive
      }
    }
    datoCmsWebHomeScreenPrd @include(if: $prdEnv) {
      ...HomeScreenPrd
      trendingCoaches {
        ...PrdCoachPreview
      }
      latestLives {
        ...PrdLatestLive
      }
    }
    datoCmsWebHomeScreen @include(if: $uatEnv) {
      ...HomeScreen
      uatTrendingCoaches {
        ...UatCoachPreview
      }
      uatLatestLives {
        ...UatLatestLive
      }
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: homeScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: homeScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: homeScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: homeScreenMetaTags {
        ...PageMeta
      }
    }
  }
`

export default IndexPage
