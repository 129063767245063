import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../ui/theme"
import { bgImageCss } from "../../ui/shared"
import { DESKTOP_BREAKPOINT } from "../../ui/responsive"
import { ROUTES } from "../../navigation/routes"
import { useOnCoachClick } from "../../navigation/hooks"
import PriceText from "../../ui/components/PriceText/PriceText"

const StyledContainer = styled.div``

const StyledImageContainer = styled.div<{ image: string }>`
  ${bgImageCss};
  background-image: url(${props => props.image});
  width: 227px;
  height: 360px;
  position: relative;
  border-radius: ${theme.borderRadius.common}px;

  ${DESKTOP_BREAKPOINT} {
    width: 271px;
    height: 430px;
  }
`

const StyledLabel = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  background-color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish}px;
  padding: 7px 12px;
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.bold};
  border-radius: ${theme.borderRadius.small}px;
`

const StyledInfo = styled.div`
  margin-top: 4px;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 6px;
  }
`

const StyledName = styled.div`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.medium}px;
  line-height: 28px;
  margin-bottom: 4px;
`

// const StyledDescription = styled.div`
//   font-size: ${theme.fontSizes.smallish}px;
//   line-height: 18px;
// `

const CoachCard: React.FC<{
  subscriptionPrice: string
  name: string
  slug: string
  coachId: string
  image: string
  isPlaceholder: boolean
}> = ({ name, slug, coachId, image, subscriptionPrice, isPlaceholder }) => {
  const onClick = useOnCoachClick()
  return (
    <Link to={ROUTES.coach.paramPath(slug)} onClick={() => onClick(coachId)}>
      <StyledContainer>
        <StyledImageContainer image={image}>
          <StyledLabel>
            {" "}
            {isPlaceholder ? (
              <p>Coming Soon</p>
            ) : (
              <PriceText
                price={Number(subscriptionPrice)}
                prefix="$"
                suffix=" USD / month"
              />
            )}
          </StyledLabel>
        </StyledImageContainer>
        <StyledInfo>
          <StyledName>{name}</StyledName>
          {/* <StyledDescription>Coach description</StyledDescription> */}
        </StyledInfo>
      </StyledContainer>
    </Link>
  )
}

export default CoachCard
