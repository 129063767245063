import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import { ResponsiveMaxWidthContent } from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import ActionButton from "../../../../ui/buttons/ActionButton/ActionButton"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  showOnDesktopCss,
} from "../../../../ui/responsive"
import { outlineTextCss } from "../HomeIntro/HomeIntro"
import { retinaMediaQuery } from "../../../../ui/helpers"
import { fancyWideFontCss } from "../../../../ui/typography/fonts"
import { ROUTES } from "../../../../navigation/routes"

const StyledContainer = styled.section<{
  image: string
  image2x: string
}>`
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};
  
  ${MOBILE_BREAKPOINT} {
  position: relative;
  
    &::before {
      content: '';
      position:  absolute;
      left: 50px;
      right: 50px;
      top: 0;
      bottom: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-image: url(${props => props.image});
      
      ${retinaMediaQuery} {
        background-image: url("${props => props.image2x}");
      }
    }
  
  }
  
`

const StyledContent = styled(ResponsiveMaxWidthContent)`
  position: relative;
  padding-top: 78px;
  padding-bottom: 45px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 122px;
    padding-bottom: 113px;
  }
`

const StyledHeading = styled.h3`
  ${fancyWideFontCss};
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 37.5px;
  text-transform: uppercase;

  span:first-child {
    ${outlineTextCss};
    display: inline-block;
    word-break: break-all;

    &:first-child {
      margin-bottom: 12.7px;
    }
  }

  ${DESKTOP_BREAKPOINT} {
    font-size: 72px;
    line-height: 50px;
  }
`

const StyledDescription = styled.p`
  font-weight: ${theme.fontWeights.bold};
  margin-top: 13.25px;

  ${MOBILE_BREAKPOINT} {
    margin-bottom: 280px;
  }

  @media (min-width: 770px) and (max-width: 870px) {
    max-width: 360px;
  }

  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.medium}px;
    margin-top: 51.5px;
    margin-bottom: 32px;
    margin-left: 5px;
  }

  @media (min-width: 871px) {
    max-width: 460px;
  }
`

const StyledDesktopImage = styled.div<{
  image: string
  image2x: string
}>`
  ${showOnDesktopCss};
  position: absolute;
  right: 130px;
  bottom: 0;
  width: 500px;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-image: url(${props => props.image});
  
  ${retinaMediaQuery} {
    background-image: url("${props => props.image2x}");
  }
  
`

const StyledButtonWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    max-width: 253px;
    margin-left: 5px;
  }
`

const LearnMoreSection: React.FC<{
  description: string
  desktopImage: [string, string]
  mobileImage: [string, string]
  learnMoreCtaDestination: string
}> = ({ description, desktopImage, mobileImage, learnMoreCtaDestination }) => (
  <StyledContainer image={mobileImage[0]} image2x={mobileImage[1]}>
    <StyledContent>
      <StyledHeading>
        <span>Youspired.</span> <br />
        <span>It's new.</span>
      </StyledHeading>
      <StyledDescription>{description}</StyledDescription>
      <StyledButtonWrapper>
        <ActionButton
          as="a"
          href={learnMoreCtaDestination}
          target="_blank"
          rel="noopener noreferrer"
        >
          I want to coach
        </ActionButton>
      </StyledButtonWrapper>
      <StyledDesktopImage image={desktopImage[0]} image2x={desktopImage[1]} />
    </StyledContent>
  </StyledContainer>
)

export default LearnMoreSection
