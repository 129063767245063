import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import { ResponsiveMaxWidthContent } from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { ResponsiveScrollableContainer } from "../../../../ui/layout"
import ContentCard from "./components/ContentCard/ContentCard"
import {
  DESKTOP_BREAKPOINT,
  showOnDesktopCss,
} from "../../../../ui/responsive"
import ActionButton, {
  ActionButtonTheme,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import {
  getHomeContentCardImage,
  IHomeContentCard,
} from "../../../../data/cms/home"
import { ROUTES } from "../../../../navigation/routes"

const StyledContainer = styled.section`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  padding-top: 75px;
  padding-bottom: 94px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 87px;
    padding-bottom: 136px;
  }
`

const StyledHeader = styled(ResponsiveMaxWidthContent)`
  margin-bottom: 24px;

  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledHeading = styled.h3`
  font-size: ${theme.fontSizes.large}px;
  line-height: ${theme.lineHeights.mediumPlus}px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.primary};
  margin-bottom: 8px;
`

const StyledList = styled(ResponsiveScrollableContainer)`
  > li:not(:last-child) {
    margin-right: 16.5px;

    ${DESKTOP_BREAKPOINT} {
      margin-right: 15.5px;
    }
  }
`

const StyledLearnMoreWrapper = styled.div`
  ${showOnDesktopCss};
  flex: 1;
  max-width: 271px;

  //a,
  //button {
  //  max-width: 271px;
  //}
`

const ContentSection: React.FC<{
  heading: string
  subheading: string
  cards: IHomeContentCard[]
}> = ({ heading, subheading, cards }) => (
  <StyledContainer>
    <StyledHeader>
      <div>
        <StyledHeading>{heading}</StyledHeading>
        <p>{subheading}</p>
      </div>
      <StyledLearnMoreWrapper>
        <ActionButton
          theme={ActionButtonTheme.SECONDARY}
          as={Link}
          to={ROUTES.about.path}
        >
          Subscribe
        </ActionButton>
      </StyledLearnMoreWrapper>
    </StyledHeader>
    <StyledList>
      {cards.map((card, index) => (
        <li key={index.toString()}>
          <ContentCard
            title={card.heading}
            description={card.description}
            image={getHomeContentCardImage(card)}
          />
        </li>
      ))}
    </StyledList>
  </StyledContainer>
)

export default ContentSection
