import React from "react"
import styled from "styled-components"
import {
  StyledListContainer,
  StyledHeader,
  StyledContainer,
  StyledTitle,
} from "../TrendingCoaches/TrendingCoaches"
import SessionPreview from "../../../../ui/components/SessionsList/components/SessionPreview/SessionPreview"
import { StyledDesktopOnly, StyledMobileOnly } from "../../../../ui/responsive"
import theme from "../../../../ui/theme"
import {
  getVodPreviewCreatedAt,
  IVODPreviewGraph,
} from "../../../../data/cms/home"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../../../ui/typography/fonts"

const StyledDesktopHeading = styled.h3`
  ${fancyCondensedFontCss};
  font-size: 38px;
  letter-spacing: 0;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 40px;

  span:first-of-type {
    margin-left: -4px;
  }

  span:last-child,
  span:first-of-type {
    ${fancyWideFontCss};
    color: ${theme.colors.primary};
    font-size: 64px;
    font-weight: 900;
    line-height: 52px;
  }

  span:last-child {
    color: ${theme.colors.secondary};
  }
`

const LatestSessions: React.FC<{
  vods: IVODPreviewGraph[]
  subheading: string
}> = ({ vods, subheading }) => (
  <StyledContainer>
    <StyledHeader>
      <StyledMobileOnly>
        <StyledTitle>Latest sessions</StyledTitle>
      </StyledMobileOnly>
      <StyledDesktopOnly>
        <StyledDesktopHeading>
          Latest <br />
          <span>lives</span>
          <span>.</span>
        </StyledDesktopHeading>
        <p>{subheading}</p>
      </StyledDesktopOnly>
    </StyledHeader>
    <StyledListContainer>
      {vods.map(vod => (
        <li key={vod.videoId}>
          <SessionPreview
            largeDesktop
            video={{
              videoId: vod.videoId,
              playbackId: vod.playbackId,
              coachId: vod.coachId,
              title: vod.title,
              duration: vod.duration,
              muxCreatedAt: getVodPreviewCreatedAt(vod),
              thumbnailOffset: vod.thumbnailOffset,
              topic: "",
            }}
          />
        </li>
      ))}
    </StyledListContainer>
  </StyledContainer>
)

export default LatestSessions
