import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import TrendingCoaches from "./components/TrendingCoaches/TrendingCoaches"
import LatestSessions from "./components/LatestSessions/LatestSessions"
import FAQSection from "./components/FAQSection/FAQSection"
import ContentSection from "./components/ContentSection/ContentSection"
import LearnMoreSection from "./components/LearnMoreSection/LearnMoreSection"
import AboutYouspired, {
  StyledExploreButton,
} from "../../components/AboutYouspired/AboutYouspired"
import HomeIntro from "./components/HomeIntro/HomeIntro"
import { DESKTOP_BREAKPOINT } from "../../ui/responsive"
import {
  getHomeContentCards,
  getHomeContentSectionHeading,
  getHomeContentSectionSubheading,
  getHomeDesktopFeaturedImage,
  getHomeFAQ,
  getHomeFAQHeading,
  getHomeIntroDescription,
  getHomeIntroDesktopCta,
  getHomeIntroDesktopDiscoverCta,
  getHomeIntroMobileCta,
  getHomeIntroMobileDiscoverCta,
  getHomeIntroPhoneScreenshot,
  getHomeIntroPhoneVideo,
  getHomeIntroSignedInCTADestination,
  getHomeIntroSignedOutCTADestination,
  getHomeItsNewDescription,
  getHomeItsNewDesktopFeaturedImage,
  getHomeItsNewMobileFeaturedImage,
  getHomeItsNewSectionCtaDestination,
  getHomeLatestLives,
  getHomeLatestLivesSubheading,
  getHomeTrendingCoaches,
  getHomeTrendingCoachesSubheading,
  IHomeScreenGraph,
} from "../../data/cms/home"
import { ROUTES } from "../../navigation/routes"
import { ActionButtonTheme } from "../../ui/buttons/ActionButton/ActionButton"

const StyledSection = styled.div`
  margin-top: 98px;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 211px;
  }
`

const StyledCoachesSection = styled(StyledSection)`
  margin-top: 46px;
  ${DESKTOP_BREAKPOINT} {
    margin-top: 122px;
  }
`

const StyledLearnMoreSection = styled.section<{
  noTopMargin?: boolean
}>`
  margin-top: ${props => (props.noTopMargin ? "" : "74px")};

  ${DESKTOP_BREAKPOINT} {
    margin-top: ${props => (props.noTopMargin ? "" : "118px")};
  }
`

const HomeScreen: React.FC<{
  cmsData: IHomeScreenGraph
}> = ({ cmsData }) => {
  const trendingCoaches = getHomeTrendingCoaches(cmsData)
  const latestLives = getHomeLatestLives(cmsData)
  return (
    <div>
      <HomeIntro
        description={getHomeIntroDescription(cmsData)}
        desktopCta={getHomeIntroDesktopCta(cmsData)}
        desktopDiscoverCta={getHomeIntroDesktopDiscoverCta(cmsData)}
        mobileCta={getHomeIntroMobileCta(cmsData)}
        mobileDiscoverCta={getHomeIntroMobileDiscoverCta(cmsData)}
        desktopFeaturedImage={getHomeDesktopFeaturedImage(cmsData)}
        appScreenshot={getHomeIntroPhoneScreenshot(cmsData)}
        introVideo={getHomeIntroPhoneVideo(cmsData)}
        signedOutCTADestination={getHomeIntroSignedOutCTADestination(cmsData)}
        signedInCTADestination={getHomeIntroSignedInCTADestination(cmsData)}
      />
      {trendingCoaches.length > 0 && (
        <StyledSection>
          <TrendingCoaches
            coaches={trendingCoaches}
            subheading={getHomeTrendingCoachesSubheading(cmsData)}
          />
        </StyledSection>
      )}
      {latestLives.length > 0 && (
        <StyledCoachesSection>
          <LatestSessions
            vods={getHomeLatestLives(cmsData)}
            subheading={getHomeLatestLivesSubheading(cmsData)}
          />
        </StyledCoachesSection>
      )}
      <StyledLearnMoreSection
        noTopMargin={trendingCoaches.length === 0 && latestLives.length === 0}
      >
        <LearnMoreSection
          description={getHomeItsNewDescription(cmsData)}
          desktopImage={getHomeItsNewDesktopFeaturedImage(cmsData)}
          mobileImage={getHomeItsNewMobileFeaturedImage(cmsData)}
          learnMoreCtaDestination={getHomeItsNewSectionCtaDestination(cmsData)}
        />
      </StyledLearnMoreSection>
      <ContentSection
        heading={getHomeContentSectionHeading(cmsData)}
        subheading={getHomeContentSectionSubheading(cmsData)}
        cards={getHomeContentCards(cmsData)}
      />
      <FAQSection
        heading={getHomeFAQHeading(cmsData)}
        faqs={getHomeFAQ(cmsData)}
      />
      <AboutYouspired includeCopy={false} />
    </div>
  )
}

export default HomeScreen
