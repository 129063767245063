import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import {
  desktopMaxWidthContentCss,
  maxWidthContentCss,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import ActionButton, {
  ActionButtonTheme,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  showOnDesktopCss,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../../../ui/responsive"
import { ROUTES } from "../../../../navigation/routes"
import { retinaMediaQuery } from "../../../../ui/helpers"
import AuthRestricted from "../../../../components/AuthRestricted/AuthRestricted"
import { fancyWideFontCss } from "../../../../ui/typography/fonts"

const StyledContainer = styled.section`
  ${DESKTOP_BREAKPOINT} {
    background: ${theme.colors.darkGrey};
    color: ${theme.colors.white};
  }
`

const StyledTopIntro = styled.div`
  ${MOBILE_BREAKPOINT} {
    background: ${theme.colors.darkGrey};
    color: ${theme.colors.white};
    padding-top: 20px;
    margin-bottom: 90px;
  }
`

export const outlineTextCss = css`
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
`

const StyledTopIntroHeading = styled.h2`
  ${fancyWideFontCss};
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 50px;
  text-transform: uppercase;
  position: relative;
  z-index: 5;

  ${DESKTOP_BREAKPOINT} {
    font-size: 86.4px;
    line-height: 60px;
    padding-top: 140px;

    > span {
      display: inline-block;

      &:last-child {
        ${outlineTextCss};
        margin-top: 12.7px;
      }

      br {
        display: none;
      }
    }
  }

  > span:first-child {
    ${MOBILE_BREAKPOINT} {
      color: ${theme.colors.primary};
    }
  }
`

const StyledImageWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    display: flex;
    justify-content: center;
    margin-top: -135px;
  }
`

const StyledImageContentWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    display: flex;
    margin-top: 70px;
  }
`

const StyledImagePlaceholder = styled.div<{
  image: string
  image2x: string
}>`
  width: 280px;
  height: 500px;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  position: relative;

  ${MOBILE_BREAKPOINT} {
    margin-bottom: -50px;
  }

  ${DESKTOP_BREAKPOINT} {
    width: 176px;
    height: 312px;
    margin-left: 46px;
    margin-right: 60px;
    margin-bottom: -60px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 5px solid ${theme.colors.primary};
      transform: rotate(-26deg) translate(2px, 1px) scale(0.88);
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`

const StyledIntroCTASection = styled.div`
  max-width: 280px;
  margin: 0 auto;

  p {
    font-size: ${theme.fontSizes.medium}px;
    line-height: 28px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 19px;
  }
`

const StyledMobileWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss}
  }
`

const StyledDesktopWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    position: relative;
  }
`

const StyledDesktopContent = styled(StyledDesktopOnly)`
  max-width: 351px;
  padding-top: 46px;

  p {
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: 30px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 26px;
  }

  a,
  button {
    max-width: 253px;
  }
`

const StyledDesktopBigImage = styled.div<{
  image: string
  image2x: string
}>`
  ${showOnDesktopCss};
  position: absolute;
  right: 0;
  bottom: 0;
  width: 500px;
  top: 0;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/svgs/youspiredMark.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 100px;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url(${props => props.image});
    
    ${retinaMediaQuery} {
      background-image: url("${props => props.image2x}");
    }
  }
  
`

const HomeIntro: React.FC<{
  description: string
  desktopCta: string
  desktopDiscoverCta: string
  mobileCta: string
  mobileDiscoverCta: string
  desktopFeaturedImage: [string, string]
  appScreenshot: [string, string]
  introVideo: string
  signedOutCTADestination: string
  signedInCTADestination: string
}> = ({
  description,
  desktopCta,
  desktopDiscoverCta,
  mobileCta,
  mobileDiscoverCta,
  desktopFeaturedImage,
  appScreenshot,
  introVideo,
  signedOutCTADestination,
  signedInCTADestination,
}) => (
  <StyledContainer>
    <StyledDesktopWrapper>
      <StyledTopIntro>
        <StyledMobileWrapper>
          <StyledTopIntroHeading>
            <span>
              Real <br />
              talk.
            </span>{" "}
            <br />
            <span>
              Real <br />
              time.
            </span>
          </StyledTopIntroHeading>
          <StyledImageContentWrapper>
            <StyledImageWrapper>
              <StyledImagePlaceholder
                image={appScreenshot[0]}
                image2x={appScreenshot[1]}
              >
                <video muted autoPlay loop playsInline>
                  <source src={introVideo} type="video/mp4" />
                </video>
              </StyledImagePlaceholder>
            </StyledImageWrapper>
            <StyledDesktopContent>
              <p>{description}</p>
              <div>
                <AuthRestricted authenticatedOnly={false}>
                  <ActionButton
                    as="a"
                    href={signedOutCTADestination}
                  >
                    {desktopCta}
                  </ActionButton>
                </AuthRestricted>
                <AuthRestricted>
                  <ActionButton
                    theme={ActionButtonTheme.SECONDARY}
                    as="a"
                    href={signedInCTADestination}
                  >
                    {desktopDiscoverCta}
                  </ActionButton>
                </AuthRestricted>
              </div>
            </StyledDesktopContent>
          </StyledImageContentWrapper>
        </StyledMobileWrapper>
      </StyledTopIntro>
      <StyledMobileOnly>
        <StyledIntroCTASection>
          <p>{description}</p>
          <div>
            <AuthRestricted authenticatedOnly={false}>
              <ActionButton
                as="a"
                href={signedOutCTADestination}
              >
                {mobileCta}
              </ActionButton>
            </AuthRestricted>
            <AuthRestricted>
              <ActionButton
                theme={ActionButtonTheme.SECONDARY}
                as="a"
                href={signedInCTADestination}
              >
                {mobileDiscoverCta}
              </ActionButton>
            </AuthRestricted>
          </div>
        </StyledIntroCTASection>
      </StyledMobileOnly>
      <StyledDesktopBigImage
        image={desktopFeaturedImage[0]}
        image2x={desktopFeaturedImage[1]}
      />
    </StyledDesktopWrapper>
  </StyledContainer>
)

export default HomeIntro
