import React from "react"
import styled from "styled-components"
import theme from "../../../../../../ui/theme"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../../../../../ui/responsive"

const StyledContainer = styled.div<{
  image: string
}>`
  height: 306px;
  width: 231.47px;
  border-radius: ${theme.borderRadius.common}px;
  background-color: ${theme.colors.primary};
  background-size: cover;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
  padding: 68px 28px 28px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url(${props => props.image});

  li:nth-of-type(even) & {
    background-color: ${theme.colors.secondary};
  }

  ${MOBILE_BREAKPOINT} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${DESKTOP_BREAKPOINT} {
    padding-top: 120px;
    height: 400px;
    width: 292.87px;
  }
`

const StyledHeading = styled.h4`
  font-size: ${theme.fontSizes.mediumPlus}px;
  line-height: 30px;
  font-weight: ${theme.fontWeights.bold};
  padding-right: 10px;

  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.large}px;
    line-height: ${theme.lineHeights.large}px;
  }
`

const StyledDescription = styled.p`
  ${MOBILE_BREAKPOINT} {
    font-size: ${theme.fontSizes.smallish}px;
    line-height: 20px;
  }
  ${DESKTOP_BREAKPOINT} {
    font-weight: ${theme.fontWeights.bold};
    margin-top: 18px;
  }
`

const ContentCard: React.FC<{
  title: string
  description: string
  image: string
}> = ({ title, description, image }) => (
  <StyledContainer image={image}>
    <StyledHeading>{title}</StyledHeading>
    <StyledDescription>{description}</StyledDescription>
  </StyledContainer>
)

export default ContentCard
