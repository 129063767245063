import { graphql } from "gatsby"
import { get } from "lodash"

export interface ICoachPreviewGraph {
  coachId: string
  slug: string
  name: string
  profilePicture: {
    url: string
  }
  topics: string
  subscriptionPrice: number | null
  placeholder: boolean
}

export const getCoachPreviewPrice = (data: ICoachPreviewGraph): number => {
  const price = get(data, "subscriptionPrice", null) || null
  if (price) {
    return price / 100
  }
  return 0
}

export const getCoachPreviewSlug = (data: ICoachPreviewGraph): string => {
  return get(data, "slug", "") || ""
}

export const getCoachId = (data: ICoachPreviewGraph): string => {
  return get(data, "coachId", "") || ""
}

export const getCoachName = (data: ICoachPreviewGraph): string => {
  return get(data, "name", "") || ""
}

export const getCoachImage = (data: ICoachPreviewGraph): string => {
  return get(data, "profilePicture.url", "") || ""
}

export const getCoachTopics = (data: ICoachPreviewGraph): string[] => {
  const topics = get(data, "topics", "") || ""
  return topics.split("¶")
}

export const isPlaceholder = (data: ICoachPreviewGraph): boolean => {
  return get(data, "placeholder", false) || false
}

export const query = graphql`
  fragment PrdCoachPreview on DatoCmsCoachPrd {
    coachId
    slug
    profilePicture {
      url
    }
    name
    topics
    subscriptionPrice
    placeholder
  }
  fragment UatCoachPreview on DatoCmsCoachUat {
    coachId
    slug
    profilePicture {
      url
    }
    name
    topics
    subscriptionPrice
    placeholder
  }
  fragment DevCoachPreview on DatoCmsCoachDev {
    coachId
    slug
    profilePicture {
      url
    }
    name
    topics
    subscriptionPrice
    placeholder
  }
  fragment CoachPreview on DatoCmsCoachStg {
    coachId
    slug
    profilePicture {
      url
    }
    name
    topics
    subscriptionPrice
    placeholder
  }
`
