import { graphql } from "gatsby"
import { get } from "lodash"
import { ICoachPreviewGraph } from "./coachPreview"
import { getEnvKey } from "../../utils/env"
import { ROUTES } from "../../navigation/routes"

export interface IHomeContentCard {
  heading: string
  description: string
  image: {
    url: string
  }
}

export const getHomeContentCardImage = (data: IHomeContentCard): string => {
  return get(data, "image.url", "") || ""
}

export interface IVODPreviewGraph {
  coachId: string
  meta: {
    createdAt: string
  }
  videoId: string
  playbackId: string
  title: string
  thumbnailOffset: number
  duration: number
}

export const getVodPreviewCreatedAt = (data: IVODPreviewGraph): number => {
  const createdAt = get(data, "meta.createdAt", "")
  try {
    return new Date(createdAt).getTime()
  } catch (error) {
    console.error(error)
    return 0
  }
}

export interface IFAQ {
  question: string
  answer: string
}

export interface IHomeScreenGraph {
  introDescription: string
  introPhoneVideo: {
    url: string
  }
  introPhoneScreenshot: {
    url: string
  }
  introPhoneScreenshot2x: {
    url: string
  }
  introMobileCta: string
  introMobileDiscoverCta: string
  introDesktopDiscoverCta: string
  introDesktopCta: string
  introDesktopFeaturedImage: {
    url: string
  }
  introDesktopFeaturedImage2x: {
    url: string
  }
  trendingCoachesSubheading: string
  latestLivesSubheading: string
  itSNewDescription: string
  itSNewSectionFeaturedImage: {
    url: string
  }
  itSNewSectionFeaturedImage2x: {
    url: string
  }
  itSNewSectionMobileFeaturedImage: {
    url: string
  }
  itSNewSectionMobileFeaturedImage2x: {
    url: string
  }
  itSNewSectionCtaDestination: string
  contentSectionHeading: string
  contentSectionSubheading: string
  contentCards: IHomeContentCard[]
  stagingTrendingCoaches?: ICoachPreviewGraph[]
  uatTrendingCoaches?: ICoachPreviewGraph[]
  developmentTrendingCoaches?: ICoachPreviewGraph[]
  trendingCoaches?: ICoachPreviewGraph[]
  developmentLatestLives?: IVODPreviewGraph[]
  uatLatestLives?: IVODPreviewGraph[]
  stagingLatestLives?: IVODPreviewGraph[]
  latestLives?: IVODPreviewGraph[]
  faqHeading: string
  faq: IFAQ[]
  introSignedOutCtaDestination: string
  introSignedInCtaDestination: string
}

export const getHomeIntroSignedOutCTADestination = (
  data: IHomeScreenGraph
): string => {
  return (
    get(data, "introSignedOutCtaDestination", ROUTES.signUp.path) ||
    ROUTES.signUp.path
  )
}

export const getHomeIntroSignedInCTADestination = (
  data: IHomeScreenGraph
): string => {
  return (
    get(data, "introSignedInCtaDestination", ROUTES.discover.path) ||
    ROUTES.discover.path
  )
}

export const getHomeFAQHeading = (data: IHomeScreenGraph): string => {
  return get(data, "faqHeading", "") || ""
}

export const getHomeFAQ = (data: IHomeScreenGraph): IFAQ[] => {
  return get(data, "faq", []) || []
}

export const getHomeContentSectionHeading = (
  data: IHomeScreenGraph
): string => {
  return get(data, "contentSectionHeading", "") || ""
}

export const getHomeContentSectionSubheading = (
  data: IHomeScreenGraph
): string => {
  return get(data, "contentSectionSubheading", "") || ""
}

export const getHomeContentCards = (
  data: IHomeScreenGraph
): IHomeContentCard[] => {
  const contentSectionCards = get(data, "contentCards", []) || []
  return contentSectionCards
}

export const getHomeLatestLives = (
  data: IHomeScreenGraph
): IVODPreviewGraph[] => {
  const key = getEnvKey({
    devKey: "developmentLatestLives",
    stgKey: "stagingLatestLives",
    uatKey: "uatLatestLives",
    prdKey: "latestLives",
    defaultKey: "stagingLatestLives",
  })
  const latestLives = get(data, key, []) || []
  return latestLives
}

export const getHomeTrendingCoaches = (
  data: IHomeScreenGraph
): ICoachPreviewGraph[] => {
  const key = getEnvKey({
    devKey: "developmentTrendingCoaches",
    stgKey: "stagingTrendingCoaches",
    uatKey: "uatTrendingCoaches",
    prdKey: "trendingCoaches",
    defaultKey: "stagingTrendingCoaches",
  })
  const trendingCoaches = get(data, key, []) || []
  return trendingCoaches
}

export const getHomeTrendingCoachesSubheading = (
  data: IHomeScreenGraph
): string => {
  return get(data, "trendingCoachesSubheading", "") || ""
}

export const getHomeLatestLivesSubheading = (
  data: IHomeScreenGraph
): string => {
  return get(data, "latestLivesSubheading", "") || ""
}

export const getHomeItsNewDescription = (data: IHomeScreenGraph): string => {
  return get(data, "itSNewDescription", "") || ""
}

export const getHomeItsNewSectionCtaDestination = (
  data: IHomeScreenGraph
): string => {
  return get(data, "itSNewSectionCtaDestination", "") || ""
}

export const getHomeIntroDescription = (data: IHomeScreenGraph): string => {
  return get(data, "introDescription", "") || ""
}

export const getHomeIntroDesktopCta = (data: IHomeScreenGraph): string => {
  return get(data, "introDesktopCta", "") || ""
}

export const getHomeIntroDesktopDiscoverCta = (
  data: IHomeScreenGraph
): string => {
  return get(data, "introDesktopDiscoverCta", "") || ""
}

export const getHomeIntroMobileCta = (data: IHomeScreenGraph): string => {
  return get(data, "introMobileCta", "") || ""
}

export const getHomeIntroMobileDiscoverCta = (
  data: IHomeScreenGraph
): string => {
  return get(data, "introMobileDiscoverCta", "") || ""
}

export const getHomeItsNewDesktopFeaturedImage = (
  data: IHomeScreenGraph
): [string, string] => {
  const image = get(data, "itSNewSectionFeaturedImage.url", "") || ""
  const image2x = get(data, "itSNewSectionFeaturedImage2x.url", "") || image
  return [image, image2x]
}

export const getHomeItsNewMobileFeaturedImage = (
  data: IHomeScreenGraph
): [string, string] => {
  const image = get(data, "itSNewSectionMobileFeaturedImage.url", "") || ""
  const image2x =
    get(data, "itSNewSectionMobileFeaturedImage2x.url", "") || image
  return [image, image2x]
}

export const getHomeDesktopFeaturedImage = (
  data: IHomeScreenGraph
): [string, string] => {
  const image = get(data, "introDesktopFeaturedImage.url", "") || ""
  const image2x = get(data, "introDesktopFeaturedImage2x.url", "") || image
  return [image, image2x]
}

export const getHomeIntroPhoneScreenshot = (
  data: IHomeScreenGraph
): [string, string] => {
  const image = get(data, "introPhoneScreenshot.url", "") || ""
  const image2x = get(data, "introPhoneScreenshot2x.url", "") || image
  return [image, image2x]
}

export const getHomeIntroPhoneVideo = (data: IHomeScreenGraph): string => {
  return get(data, "introPhoneVideo.url", "") || ""
}

export const query = graphql`
  fragment LatestLive on DatoCmsVideoStg {
    coachId
    meta {
      createdAt
    }
    videoId
    playbackId
    title
    thumbnailOffset
    duration
  }
  fragment DevLatestLive on DatoCmsVideoDev {
    coachId
    meta {
      createdAt
    }
    videoId
    playbackId
    title
    thumbnailOffset
    duration
  }
  fragment UatLatestLive on DatoCmsVideoUat {
    coachId
    meta {
      createdAt
    }
    videoId
    playbackId
    title
    thumbnailOffset
    duration
  }
  fragment PrdLatestLive on DatoCmsVideoPrd {
    coachId
    meta {
      createdAt
    }
    videoId
    playbackId
    title
    thumbnailOffset
    duration
  }

  fragment HomeScreenDev on DatoCmsWebHomeScreenDev {
    introDescription
    introPhoneVideo {
      url
    }
    introPhoneScreenshot {
      url
    }
    introPhoneScreenshot2x {
      url
    }
    introMobileCta
    introDesktopDiscoverCta
    introMobileDiscoverCta
    introDesktopCta
    introDesktopFeaturedImage {
      url
    }
    introDesktopFeaturedImage2x {
      url
    }
    trendingCoachesSubheading
    latestLivesSubheading
    itSNewDescription
    itSNewSectionFeaturedImage {
      url
    }
    itSNewSectionFeaturedImage2x {
      url
    }
    itSNewSectionMobileFeaturedImage {
      url
    }
    itSNewSectionMobileFeaturedImage2x {
      url
    }
    itSNewSectionCtaDestination
    contentSectionHeading
    contentSectionSubheading
    contentCards {
      heading
      description
      image {
        url
      }
    }
    faqHeading
    faq {
      question
      answer
    }
    introSignedOutCtaDestination
    introSignedInCtaDestination
  }

  fragment HomeScreenStg on DatoCmsWebHomeScreenStg {
    introDescription
    introPhoneVideo {
      url
    }
    introPhoneScreenshot {
      url
    }
    introPhoneScreenshot2x {
      url
    }
    introMobileCta
    introDesktopDiscoverCta
    introMobileDiscoverCta
    introDesktopCta
    introDesktopFeaturedImage {
      url
    }
    introDesktopFeaturedImage2x {
      url
    }
    trendingCoachesSubheading
    latestLivesSubheading
    itSNewDescription
    itSNewSectionFeaturedImage {
      url
    }
    itSNewSectionFeaturedImage2x {
      url
    }
    itSNewSectionMobileFeaturedImage {
      url
    }
    itSNewSectionMobileFeaturedImage2x {
      url
    }
    itSNewSectionCtaDestination
    contentSectionHeading
    contentSectionSubheading
    contentCards {
      heading
      description
      image {
        url
      }
    }
    faqHeading
    faq {
      question
      answer
    }
    introSignedOutCtaDestination
    introSignedInCtaDestination
  }

  fragment HomeScreen on DatoCmsWebHomeScreen {
    introDescription
    introPhoneVideo {
      url
    }
    introPhoneScreenshot {
      url
    }
    introPhoneScreenshot2x {
      url
    }
    introMobileCta
    introDesktopDiscoverCta
    introMobileDiscoverCta
    introDesktopCta
    introDesktopFeaturedImage {
      url
    }
    introDesktopFeaturedImage2x {
      url
    }
    trendingCoachesSubheading
    latestLivesSubheading
    itSNewDescription
    itSNewSectionFeaturedImage {
      url
    }
    itSNewSectionFeaturedImage2x {
      url
    }
    itSNewSectionMobileFeaturedImage {
      url
    }
    itSNewSectionMobileFeaturedImage2x {
      url
    }
    itSNewSectionCtaDestination
    contentSectionHeading
    contentSectionSubheading
    contentCards {
      heading
      description
      image {
        url
      }
    }
    faqHeading
    faq {
      question
      answer
    }
    introSignedOutCtaDestination
    introSignedInCtaDestination
  }

  fragment HomeScreenPrd on DatoCmsWebHomeScreenPrd {
    introDescription
    introPhoneVideo {
      url
    }
    introPhoneScreenshot {
      url
    }
    introPhoneScreenshot2x {
      url
    }
    introMobileCta
    introDesktopDiscoverCta
    introMobileDiscoverCta
    introDesktopCta
    introDesktopFeaturedImage {
      url
    }
    introDesktopFeaturedImage2x {
      url
    }
    trendingCoachesSubheading
    latestLivesSubheading
    itSNewDescription
    itSNewSectionFeaturedImage {
      url
    }
    itSNewSectionFeaturedImage2x {
      url
    }
    itSNewSectionMobileFeaturedImage {
      url
    }
    itSNewSectionMobileFeaturedImage2x {
      url
    }
    itSNewSectionCtaDestination
    contentSectionHeading
    contentSectionSubheading
    contentCards {
      heading
      description
      image {
        url
      }
    }
    faqHeading
    faq {
      question
      answer
    }
    introSignedOutCtaDestination
    introSignedInCtaDestination
  }
`
